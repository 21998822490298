<template>
    <div class="subscription">

        <h4 class="title is-4">{{$t('Subscription')}}</h4>

        <div class="columns">
            <div class="column" v-for="(plan, index) in subscriptionPlans" :key="plan.id">
                <div class="box">
                    {{$t(plan.name)}}, month: {{plan.month_price}}, year: {{plan.year_price}}


                    <div v-if="plan.limits.length" class="has-background-grey-lighter">
                        {{$t('limits')}}:
                        <ul>
                            <li v-for="limit in plan.limits" :key="limit.id">{{$t(limit.name)}}: {{limit.value}}</li>
                        </ul>
                    </div>

                    <span v-else class="has-background-grey-lighter"> {{$t('no limits')}}</span>

                    <template v-if="isCurrentUserPlan(plan)">
                        <b-tag type="is-success" >{{$t('current')}}</b-tag>
                        <span v-if="user.subscription.end">{{ $t('subscription end') }}: {{ user.subscription.end | dateFromNow }}</span>
                    </template>

                    <b-button @click="proceedSubscription(plan)" v-if="index > 0 && (isCurrentUserPlan(plan) || userCanUpgradeToPlan(plan))">

                        <template>
                            {{isCurrentUserPlan(plan) ? $t('renew subscription') : $t('buy')}}

                        </template>
                    </b-button>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import {GET_SUBSCRIPTION_PLANS} from "../store/modules/subscriptions/action-types";
    import {mapActions, mapState} from "vuex";

    export default {
        name: "Subscribe",
        title(){ return this.$t('Start subscription');},
        computed: {
            ...mapState('subscriptions', [
                'subscriptionPlans',
            ]),
            user(){
                return this.$store.state.auth.user;
            },
        },
        methods: {
            ...mapActions('subscriptions', [
                GET_SUBSCRIPTION_PLANS,
            ]),
            proceedSubscription(plan){
                this.$router.push({
                    name: 'plan',
                    params: {
                        plan_id: plan.id,
                        plan: plan,
                    }
                });
            },
            userCanUpgradeToPlan(plan){
                return plan.id > this.user.subscription.plan.id;
            },
            isCurrentUserPlan(plan){
                return this.user.subscription && this.user.subscription.plan.id === plan.id;
            },
        },
        mounted() {
            if (!this.subscriptionPlans){
                this[GET_SUBSCRIPTION_PLANS]();
            }
            this.$Progress.finish();
        }
    };
</script>

<style scoped>

</style>